import React, { useState } from 'react';
import { FileUploader } from 'react-drag-drop-files';
import { X } from 'tabler-icons-react';
import { fileToBase64 } from '@utils/helpers';
import {BiUpload} from "react-icons/all";

const ImageInput = ({
                      handleChange,
                      allowPreviewImage = true,
                      imagePath,
                      disabled,
                      error
                    }) => {
  const [previewImage, setPreviewImage] = useState(null);
  const [fileName, setFileName] = useState('');
  const onDelete = () => {
    setPreviewImage(null);
    setFileName('');
    if (handleChange) {
      handleChange(null);
    }
  };

  const onChange = async (file) => {
    setFileName(file.name);
    setPreviewImage(URL.createObjectURL(file));
    if (handleChange) {
      const convertedFile = await fileToBase64(file);
      handleChange(convertedFile);
    }
  };

  return (
    <div className="flex flex-col" data-testid="CFile-input">
      <FileUploader name="file" handleChange={onChange}>
        <div className="relative flex rounded-md group border-gray-600 border-dashed border-2 bg-gray-100 hover:border-gray-400 justify-center cursor-pointer">
          <div className="flex my-5">
            {imagePath ? (
              // eslint-disable-next-line jsx-a11y/img-redundant-alt
              <img
                src={imagePath}
                width={160}
                height={90}
                alt="image"
                className={`${disabled && 'grayscale-100'}`}
              />
            ) : (
              <div className="flex flex-row text-gray-600 group-hover:text-gray-400 font-light font-inter items-center">
                 Ngarko
                <BiUpload className='ml-2' size={25}/>
              </div>
            )}
          </div>
        </div>
      </FileUploader>
      {previewImage && (
        <div className="relative flex items-center justify-center border p-5 bg-gray-100 mt-3 rounded-md">
          {allowPreviewImage ? (
            <div>
              <div className="flex h-24 justify-center mb-1">
                <img className="shadow-md hover:scale-110" src={previewImage} alt="logo" />
              </div>
              <div className="items-center px-5 text-xs break-words">{fileName}</div>
            </div>
          ) : (
            <div className="px-5 text-xs break-words">{fileName}</div>
          )}
          <div className="absolute top-2 right-2">
            <X
              className="h-5 text-gray-500 hover:cursor-pointer rounded-full hover:text-[#C12026] hover:bg-gray-200"
              onClick={onDelete}
            />
          </div>
        </div>
      )}
      {error && <label className="xs:text-xs md:text-sm text-red-500 font-inter opacity-90 text-left p-1">{error}</label>}
    </div>
  );
};

export default ImageInput;
