import React from 'react';
import '@assets/css/style.css';
const List = () => {
  return (
    <div className="relative bg-[#FFF9E6] w-full lg:pt-14">
      <div className='w-full lg:w-[950px] flex flex-col justify-center align-middle items-center text-center mx-auto bg-[#C12026] rounded-2xl py-10 px-6 lg:px-20 lg:pt-10 lg:pb-20 text-[#FFF9E6]'>
        <div className='flex flex-col justify-center items-center lg:w-[750px] bg-white rounded-2xl p-6 lg:p-12 mt-5 lg:mt-10 space-y-5'>
          <div className='flex flex-col w-full px-1 rounded-xl font-inter text-[#5A5A5A] font-light space-y-5 text-[15px] text-left'>
            <p className='text-[18px] lg:text-[24px] text-center'>Kushtet e privatësisë <b className='font-semibold'>së Lojës Promocionale 7days</b> të organizuar nga Shoqëria “ALFA”Sh.A.</p>
            <p>Alfa sh.a (më poshtë referuar Organizatori) është kontrolluesi i të dhënave dhe përgjegjëse për përpunimin e të dhënave personale që kanë të bëjnë me te dhënat personale, fotografitë dhe regjistrimet video dhe audio ("Regjistrimet") e pjesëmarrësve ne promocion, të mbledhura nga Organizatori me qëllim promocionin “7DAYS”.</p>
            <p>Organizatori do të përpunojë të dhënat personale të Pjesëmarrësve në përputhje me të gjitha ligjet e zbatueshme të privatësisë “Për Mbrojtjen E të Dhënave Personale” si dhe aktet nënligjore përkatëse.</p>
            <p>Duke u bërë pjesë e këtij shorti dhe pranuar kushtet e privatësisë, ju si pjesëmarrës, keni dhëne pëlqimin për çdo përdorim të të dhënave personale për qëllimet e përcaktuara si me poshtë.</p>
            <p className='font-semibold'>Maksimumi i pjesëmarrjes në lojë do të jetë 20 herë në ditë për një person me 20 kuponë tatimorë të ndryshëm.</p>
            <div className='flex flex-col'>
              <p className='font-semibold'>Udhëzime të përgjithshme për në rast se shpalleni fitues në shorte:</p>
              <p>Duke marrë pjesë në lojën Promocionale “7DAYS”, me regjistrimin e të dhënave tuaja në web <a href="http://www.7days.al" className='text-blue-500 underline' target="_blank" rel="noopener noreferrer">www.7days.al</a> sipas rregullores së lojës, Pjesëmarrësi pranon që të telefonohet në numrin e telefonit e shënuar në web gjatë regjistrimit për të marrë informacionin se ka qenë i përzgjedhur si fitues/e. Për të verifikuar të dhënat e tij, Pjesëmarrësit të përzgjedhur në mënyrë rastësorë mund të pyetet nga Organizatori për verifikimin e kredencialeve të regjistruara në web (Psh: emër, mbiemër, vendbanimi, kartë identiteti, kuponin tatimor etj.). Vetëm pasi të jenë bërë verifikimet e nevojshme për pjesëmarrjen e rregullt në lojë do të vijohet me procedurat e mëtejshme për tërheqjen e çmimit të fituar. Për Pjesëmarrësit e shpallur si fitues të kategorisë Çmim Ditor apo kategorisë Çmim Javor, me kupona dhuratë, ky kupon do t'i vihet në dispozicion fizikisht në adresën e dhënë nga fituesi në Republikën e Shqipërisë.</p>
            </div>
            <p>Kur të marrë çmimin, fituesi është i detyruar të nënshkruajë pëlqimin për pranimin e çmimit duke plotësuar një formular dhe duke nën shkruar atë si një provë e tërheqjes së çmimit. Nëse fituesi nuk paraqet një mjet identifikimi me të dhënat personale (emrin, mbiemrin dhe adresën) dhe një foto të faturës fiskale brenda afateve të caktuara, siç është përshkruar në Rregulloren e lojës, ose nuk kërkon çmimin në përputhje me këto Rregulla, ai humbet të drejtën për të njëjtin dhe kështu detyrimet e mëtejshme të Organizatorit ndaj fituesit përfundojnë dhe çmimi në fjalë do t’i jepet fituesit rezervë. Kur të pranojnë çmimet, fituesi, d.m.th. personi i autorizuar nga fituesi për ta bërë këtë me një prokurë të veçantë, nënshkruan një deklaratë për pranimin e çmimit, e cila përfundon të gjitha detyrimet e mëtejshme të Organizatorit të Lojës Promocionale ndaj fituesit, të gjitha në përputhje me këtë rregullore. Çmimet e fituara nuk janë të transferueshme dhe fituesit nuk mund t’i shkëmbejnë ato me Organizatorin për një ekuivalent monetar ose për ndonjë çmim tjetër.</p>
            <p>Me nënshkrimin e këtij formulari nga fituesi ose personi i autorizuar nga fituesi, çmimi do të konsiderohet i dorëzuar dhe në rast të dëmtimit apo humbjes së kuponit nga fituesi, Organizatori nuk do të jetë përgjegjës.</p>
            <p>Në rastin e fituesit për kategorinë Çmim Kryesor, Pjesëmarrësi pranon të marrë informacion në numrin e shënuar në web gjatë regjistrimit te të dhënave. Ai do të konsiderohet fitues vetëm pasi të ketë verifikuar të gjithë kredencialet e tij/saj dhe të ketë vendosur në dispozicionin e Organizatorit çdo të dhënë të nevojshme (psh: kartË identiteti, kuponin tatimor, emër, mbiemër, email etj.). Vetëm pasi të jenë verifikuar të gjithë të dhënat si dhe ndjekja e saktë e të gjithë rregullave të lojës ai/ajo do të konsiderohet fitues dhe do te vijohet më tej me procedurën e tërheqjes së çmimit. Për të tërhequr çmimin, fituesi/ja i kategorisë Çmimi Kryesor, duhet të paraqitet pranë zyrave të Gogel sh.p.k për të firmosur deklaratën e tërheqjes se çmimit si dhe të vendos në dispozicion të gjithë informacionet e nevojshme për tërheqjen e çmimit sipas detajeve të tërheqjes së çmimit kryesor të afishuar në web <a href="http://www.7days.al" className='text-blue-500 underline' target="_blank" rel="noopener noreferrer">www.7days.al</a></p>
            <p>Adresa: Rruga Pjetër Bogdani, Ndërtesa Rainbow Center, Kati 2, Njësia Bashkiake nr. 8, Tiranë.</p>
            <div className='flex flex-col'>
              <p className='font-semibold'>Detyrimet e përgjithshme të Pjesëmarrësve</p>
              <p>Pjesëmarrësi është i detyruar t’i përdorë bisedat brenda aplikacionit Whatsapp/Mail në përputhje me të gjitha kushtet e zbatueshme kontraktuale dhe ligjore. Pjesëmarrësi është i detyruar që të përmbahet nga shpërndarja e përmbajtjeve ndëshkuese apo nga shkeljet e të drejtave tjera të palës së tretë. Më saktësisht, është e ndaluar shpërndarja e përmbajtjeve të dëmshme për minoren, pornografisë, sulmeve ekstremiste apo raciste ose viruseve, si dhe keqpërdorimi i aplikacionit për manipulimin e mekanizmave të sigurisë të rrjetit të jashtëm, kompjuterit kryesor apo llogarive.</p>
              <p>Pjesëmarrësi pranon që të gjitha mesazhet që kanë përmbajtje të sjelljes së tillë të Pjesëmarrësit mund të dërgohen me postë elektronike, përveç nëse parashikohet ndryshe me ligj.</p>
              <p>Këto rregulla mbi përdorimin e bisedave në Whatsapp/Mail zbatohen për të gjitha marrëveshjet ndërmjet Pjesëmarrësit dhe Organizatorit të bisedave në Whatsapp/Mail.</p>
            </div>
            <div className='flex flex-col'>
              <p className='font-semibold'>Përgjegjësia për përmbajtjen në internet</p>
              <p>Pjesëmarrësi është përgjegjës për të gjithë përmbajtjen e postuar në bisedat në Whatsapp/Mail, si p.sh. tekste, regjistrime, imazhe, grafika, të dhëna, linqe etj. Pjesëmarrësi shprehimisht pajtohet që nuk do t’i shkelë të drejtat e autorit apo marka tregtare, të drejtat personale ose të drejta të personave të tretë.</p>
            </div>
            <div className='flex flex-col'>
              <p className='font-semibold'>Mbrojtja e të dhënave</p>
              <p>Të dhënat personale të mbledhura përmes aplikacionit apo mail do të përpunohen në përputhje me legjislacionin në fuqi.Mbrojtja e privatësisë dhe të dhënave personale janë çështje të një rëndësie të veçantë dhe Organizatori është i përkushtuar të zbatojë me rigorozitet të gjithë kuadrin ligjor në mbrojtje dhe respektim të tyre, veçanërisht Ligjin 9887, datë 10.03.2008 “Për Mbrojtjen e të Dhënave Personale” si dhe aktet nënligjore përkatëse.</p>
              <p>Të gjitha të dhënat e deklaruara nga Pjesëmarrësi do të përpunohen vetëm për qëllimin e përcaktuar dhe deri në përfundim te shpalljes se fituesve të Promocionit për Pjesëmarrësit Jo Fitues dhe për Pjesëmarrësit Fitues brenda 14 ditëve nga dorëzimi i dhuratës. Të gjitha të dhënat do të fshihen dhe shkatërrohen sipas ligjit në fuqi, duke mos lejuar humbjen, ndryshimin, përhapjen e pa-autorizuar ose ndërhyrjen te të dhënat personale të transmetuara, ruajtura apo përpunuara. Të dhënat personale të mbledhura nga Organizatori dhe Partneret e saj në këtë kuadër, do të trajtohen në përputhje me LIGJ Nr. 9887, datë 10.3.2008 “Për Mbrojtjen e të Dhënave Personale” i ndryshuar. Në përputhje me kuadrin ligjor, Pjesëmarrësi ka të drejtën e korrigjimit, fshirjes të të dhënave të tij personale ose kundërshtimit të përpunimit të tyre.</p>
            </div>
            <div className='flex flex-col'>
              <p className='font-semibold'>Të dhënat personale të cilat i mbledh dhe i përpunon Organizatori:</p>
              <p>• Emri dhe mbiemri, data e lindjes, vendi i lindjes, adresa e banimit, numër celulari dhe e-mail, kartë identiteti, kuponi tatimor që evidenton blerjen e produktit 7DAYS.</p>
            </div>
            <div className='flex flex-col'>
              <p className='font-semibold'>Baza ligjore dhe qëllimi i mbledhjes dhe përpunimit e të dhënave personale:</p>
              <p>Organizatori do të përpunojë të dhënat personale të Pjesëmarrësve për qëllimet e mëposhtme:</p>
              <p>- për të marrë pjesë në çdo promovim si pjesë e Promocionit “7 DAYS”</p>
              <p>- video promovuese të aktivitetit si pjesë e Promocionit “7 DAYS”</p>
            </div>
            <div className='flex flex-col'>
              <p className='font-semibold'>A jeni të detyruar që të ndani të dhënat tuaja personale me Ne?</p>
              <p>Për çdo verifikim para tërheqjes së dhuratës pjesëmarrësi është i detyruar të vendosë në dispozicion të dhënat personale të tij/saj.</p>
            </div>
            <div className='flex flex-col'>
              <p className='font-semibold'>Transferimi i të dhënave jashtë Bashkimit Europian.</p>
              <p>Ne nuk do te të transferojmë të dhënat tuaja personale në një ose më shumë vende, brenda ose jashtë vendit.</p>
            </div>
            <div className='flex flex-col'>
              <p className='font-semibold'>Të drejtat tuaja</p>
              <p>• E drejta për të fshirë të dhënat personale.</p>
              <p>Në rrethana të caktuara ju keni të drejtë të kërkoni që të kufizojmë përpunimin e të dhënave tuaja personale dhe/ose fshirjen e të dhënave tuaja. Kërkesën për fshirjen e të dhënave të lidhura me ju, mund ta paraqitni në çdo moment dhe Organizatori do ti fshijë ato. Kërkesën për fshirje të të dhënave personale mund ta kërkoni në adresën elektronike: <a href="mailto:office@gogel.al" className='text-blue-500 underline' target="_blank" rel="noopener noreferrer">office@gogel.al</a></p>
            </div>
            <div className='flex flex-col'>
              <p>• E drejta e kundërshtimit</p>
              <p>Nëse konsideroni që të drejtat tuaja janë shkelur, ju keni të drejtë për të kundërshtuar. Për të ushtruar të drejtat tuaja, dërgoni një e-mail në adresën në vijim: <a href="mailto:office@gogel.al" className='text-blue-500 underline' target="_blank" rel="noopener noreferrer">office@gogel.al</a></p>
              <p>Nëse na drejtoheni përmes e-mail për të ushtruar të drejtën tuaj, Organizatori mund të kërkojë nga ju që të identifikoheni para se të vazhdojë me kërkesën tuaj.</p>
            </div>
            <div className='flex flex-col'>
              <p className='font-semibold'>Niveli i mbrojtjes së të dhënave personale në sistemin e Organizatori:</p>
              <p>Organizatori mbron të dhënat tuaja personale me teknologji më bashkëkohore të IT-së dhe me sisteme të mbrojtjes fizike. Të gjithë të punësuarit të Organizatori veprojnë në përputhje me protokollet dhe procedurat e brendshme dhe me këtë Politikë me qëllim të ruajtjes së privatësisë tuaj dhe për të pamundësuar keqpërdorimin potencial të dhënave.</p>
            </div>
            <div className='flex flex-col'>
              <p className='font-semibold'>Kontakti</p>
              <p>Nëse keni pyetje në lidhje me këtë Deklaratë ose dëshironi informata shtesë në lidhje me mënyrën e mbrojtjes, apo dëshironi të kontaktoni personin përgjegjës te Organizatori për mbrojtjen e të dhënave-Data Protection Officer (DPO), ju lutemi që të na dërgoni një email në adresë: <a href="mailto:office@gogel.al" className='text-blue-500 underline' target="_blank" rel="noopener noreferrer">office@gogel.al</a>
              </p>
            </div>
            <p className='font-semibold'>Do ju përgjigjemi brenda tre ditë pune.</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default List;
