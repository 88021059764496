import React, {forwardRef, useImperativeHandle, useRef, useState} from 'react';
import '@assets/css/style.css';
import Input from "@core/inputs/Input";
import useFormErrors from "../../hooks/useFormErrors";
import {createPortal} from "react-dom";
import BlankModal from "@core/modal/BlankModal";
import success from '@assets/svg/success.svg';
import CodeInput from "@core/inputs/CodeInput";
import error from '@assets/svg/error.svg';
import DefaultButton from "@core/buttons/electrons/DefaultButton";
import WarningDropdown from "@components/partials/WarningDropdown";
import fature from '@assets/svg/fature.svg';
import { parse, isValid } from 'date-fns';
import ImageInput from "@core/inputs/ImageInput";
import RadioButton from "@core/buttons/electrons/RadioButton";
import {Link} from "react-router-dom";
import formValidator from "@utils/validators/FormValidator";
import DateInput from "@core/inputs/DateInput";
import DaysService from "@services/7DaysService";
import {hideSpinner, showSpinner} from "@redux/spinner/Action";
import {useDispatch} from "react-redux";

const Form = forwardRef((props, ref) => {
  const formRef = useRef(null);
  const dispatch = useDispatch();

  const [name, setName] = useState('');
  const [date, setDate] = useState('');
  const [city, setCity] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [email, setEmail] = useState('');
  const [code, setCode] = useState('');
  const [imageBase64, setImageBase64] = useState('');
  const [isOver18, setIsOver18] = useState(false);
  const [readTerms, setReadTerms] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [title, setTitle] = useState('');
  const [formErrors, setFormErrors] = useState({});
  const [key, setKey] = useState(1);
  const [showInput, setShowInput] = useState(true);

  const {clearError, getError, validateErrors, clearErrors} = useFormErrors();

  useImperativeHandle(ref, () => formRef.current);

  const closeModal = () => {
    setShowInput(true);
    setShowErrorModal(false);
    setShowSuccessModal(false);
    if (formRef.current) {
      formRef.current.scrollIntoView({behavior: 'smooth', block: 'start'});
    }
  };

  const clear = () => {
    setKey(prevKey => prevKey + 1);
    setName('');
    setDate('');
    setCity('');
    setPhoneNumber('');
    setEmail('');
    setCode('');
    setImageBase64('');
    setTitle('');
    setIsOver18(false);
    setReadTerms(false);
    clearErrors();
    setFormErrors({});
  };

  const addCode = () => {
    const eighteenYearsAgo = new Date();
    eighteenYearsAgo.setFullYear(eighteenYearsAgo.getFullYear() - 18);

    const dateRegex = /^(0[1-9]|[12][0-9]|3[01])\/(0[1-9]|1[0-2])\/\d{4}$/;
    const isValidFormat = dateRegex.test(date);

    let isValidDate = false;
    let parsedDate = null;

    if (isValidFormat) {
      parsedDate = parse(date, 'dd/MM/yyyy', new Date());
      isValidDate = isValid(parsedDate);
    }

    let errors = validateErrors(
      {
        name,
        date,
        city,
        phoneNumber,
        code,
        imageBase64,
        isOver18,
        readTerms
      },
      formValidator,
    );

    const isFormErrorsNotEmpty = Object.keys(formErrors).length > 0;

    if (errors || !isValidFormat || !isValidDate || isFormErrorsNotEmpty || (parsedDate && parsedDate > eighteenYearsAgo)) {
      if (!isValidFormat) {
        setFormErrors({date: 'Ju lutem përdorni formatin e datës DD/MM/YYYY.'});
      } else if (!isValidDate) {
        setFormErrors({date: 'Ju lutem kontrolloni që data juaj të jetë e rregullt.'});
      } else if (parsedDate && parsedDate > eighteenYearsAgo) {
        setFormErrors({date: 'Vetëm pjesëmarrësit +18 vjeç mund të marrin pjesë në ketë shorte.'});
      }
      setShowErrorModal(true);
      setTitle('Ju lutem plotësoni të gjitha fushat e detyrueshme!');
      return;
    }

    const formattedDate = parsedDate && parsedDate.toISOString().split('T')[0];

    dispatch(showSpinner());
    setIsLoading(true);

    DaysService.addCode({
      payload: {
        full_name: name,
        birth_date: formattedDate,
        city: city,
        phone_number: phoneNumber,
        email: email ? email : undefined,
        code: code,
        image: imageBase64
      }
    })
      .then(() => {
        setTitle('Urime, ju jeni pjesë e shorteut!');
        setShowInput(false);
        setShowSuccessModal(true);
        clear();
      })
      .catch((err) => {
        setShowErrorModal(true);
        setTitle(err.response?.data.message.code ? err.response?.data.message.code : 'Ju lutem kontrolloni të gjitha fushat e detyrueshme!');
        if (err.response?.data.message.code) {
          setFormErrors({code: err.response?.data.message.code});
        }
      })
      .finally(() => {
        dispatch(hideSpinner());
        setIsLoading(false);
      });
  };

  return (
    <div className="relative bg-[#FFF9E6] w-full lg:pt-14" ref={formRef}>
      <div
        className='w-full lg:w-[950px] flex flex-col justify-center align-middle items-center text-center mx-auto bg-[#C12026] rounded-2xl py-10 px-6 lg:px-20 lg:pt-10 lg:pb-20 text-[#FFF9E6]'>
        <p className='font-bold mt-4 text-[30px]'>Merr pjesë këtu</p>
        <p className='font-light mt-4 text-[18px]'>Ju mund të merrni pjesë në lojën promocionale, duke dërguar foto të
          faturës, si edhe gjashtë shifrat e fundit të kodit NIVF, që gjendet në çdo faturë tatimore në 7days.al</p>
        <p className='font-bold mt-4 text-[18px]'>Loja promocionale do të zhvilohet nga data 09.09.2024 deri më
          20.10.2024</p>
        <div
          className='flex flex-col justify-center lg:w-[750px] bg-white rounded-2xl p-6 lg:p-12 mt-5 lg:mt-10 space-y-5'>
          <Input
            label='Emër Mbiemër'
            value={name}
            placeholder='Emër Mbiemër'
            onChange={(e) => clearError('name', e.target.value, setName)}
            error={getError('name')}
          />
          <div>
            <DateInput
              label='Datëlindja'
              value={date}
              setValue={(value) => {
                clearError('date', value, setDate);
                setFormErrors({});
              }}
              error={getError('date') || formErrors?.date}
            />
            <p
              className={`${!getError('date') && 'mt-1.5'} pl-1 text-xs text-gray-500 font-inter font-thin text-left`}>Vetëm
              pjesëmarrësit +18 vjeç mund të marrin pjesë në ketë shorte.</p>
          </div>
          <Input
            label='Qyteti'
            value={city}
            placeholder='Shkruaj qytetin'
            onChange={(e) => clearError('city', e.target.value, setCity)}
            error={getError('city')}
          />
          <Input
            type='phone'
            label='Numër celulari'
            value={phoneNumber}
            onChange={(e) => {
              const numericValue = e.target.value.replace(/\D/g, '');
              clearError('phoneNumber', numericValue, setPhoneNumber);
            }}
            error={getError('phoneNumber')}
          />
          <Input
            label='Email'
            placeholder='loremipsum@gmail.com'
            required={false}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <div className='flex flex-col'>
            <div className="relative flex items-center">
              <span className='font-semibold text-left xs:text-sm md:text-lg text-[#C12026] cursor-pointer mr-2 pb-2'>
                Vendos 6 karakteret e fundit të kodit NIVF nga kuponi tatimor*
              </span>
              <WarningDropdown
                children={
                  <div className='flex flex-col items-center justify-center px-1 py-2'>
                  <span className='text-[#C12026] font-semibold text-[12px] leading-tight'>
                    *Kujdes: Në rastin që hasni vështirësi për identifikimin e kodit NIVF ju mund të përdorni kodin NSLF.
                  </span>
                    <img alt='fature' src={fature} className='mt-4 h-[320px] w-[210px]'/>
                  </div>
                }
              />
            </div>
            {showInput && <CodeInput
              value={code}
              onChange={(value) => {
                setCode(value);
                clearError('code');
                setFormErrors({});
              }}
              error={getError('code') || formErrors?.code}
            />}
          </div>
          <div className='flex flex-col'>
            <div className="relative flex items-center">
              <span className='font-semibold text-left xs:text-sm md:text-lg text-[#C12026] cursor-pointer mr-2 pb-2'>
                Ngarko foton e kuponit*
              </span>
              <WarningDropdown
                width='w-80'
                left='-left-2 md:left-5'
                children={
                  <div className='flex flex-col items-center justify-center py-3 px-2'>
                  <span className='text-[#C12026] font-semibold text-[12px] leading-tight'>
                    Sigurohu që fotoja e kuponit tatimor të jetë e plotë dhe të dallohet qartë blerja e produktit, vlera në lekë si dhe kodi NIVF. Në rast të kundërt, mund të skualifikoheni nga promocioni apo mund të mos jepet çmimi edhe nëse zgjidheni fitues!
                  </span>
                  </div>
                }
              />
            </div>
            <div className='w-full lg:max-w-[310px]'>
              <ImageInput
                key={key}
                handleChange={(value) => {
                  clearError('imageBase64')
                  setImageBase64(value)
                }}
                error={getError('imageBase64')}
              />
            </div>
          </div>
          <div className='flex flex-col space-y-2 text-[16px]'>
            <div className='flex flex-row items-start hover:cursor-pointer' onClick={() => setIsOver18(!isOver18)}>
              <RadioButton checked={isOver18} onChange={(value) => {
                setIsOver18(value);
                clearError('isOver18')
              }}/>
              <p className='ml-2.5 -mt-1.5 text-gray-500 font-inter font-thin text-left'>Konfirmoj se kam mbushur 18
                vjeç/e dhe jam rezident/e në Shqipëri.</p>
            </div>
            <div className='flex flex-row items-start hover:cursor-pointer' onClick={() => setReadTerms(!readTerms)}>
              <RadioButton checked={readTerms} onChange={(value) => {
                setReadTerms(value);
                clearError('readTerms')
              }}/>
              <p className='ml-2.5 -mt-1.5 text-gray-500 font-inter font-thin text-left'>Konfirmoj se kam lexuar
                rregulloren te website 7days.al te butoni “<Link
                  className='hover:scale-110 hover:cursor-pointer border-transparent transition ease-in-out duration-300 hover:border-[#C12026] text-[#C12026] font-semibold border-b pb-0.5 mb-0.5'
                  to={'/rregullore'}>Rregullore</Link>”
                dhe pranoj termat dhe kushtet.</p>
            </div>
            {(getError('isOver18') || getError('readTerms')) &&
              <label className="xs:text-xs md:text-sm text-red-500 font-inter opacity-90 text-left p-1">Ju lutem
                konfirmoni kushtet</label>}
          </div>
          <div className='w-full lg:max-w-[310px] pt-5 mx-auto'>
            <DefaultButton
              loading={isLoading}
              label='Përfundo aplikimin'
              bgColor='bg-[#C12026]'
              textColor='text-[#FFF9E6]'
              bgHoverColor='transform duration-300 ease-in-out hover:opacity-80'
              onClick={addCode}
            />
          </div>
        </div>
      </div>
      {createPortal(
        <BlankModal
          img={showErrorModal ? error : success}
          isOpen={showErrorModal || showSuccessModal}
          onClose={closeModal}
          title={showSuccessModal ? "Urime, ju jeni pjesë e shorteut!" : title}
          description={showSuccessModal && '*Ju lutem ruani kuponin tatimor deri në përfundim të shorteut!'}
        />,
        document.getElementById('root')
      )}
    </div>
  );
});

export default Form;
