import React, { useEffect, useRef } from 'react';
import ImageHeader from '@components/partials/ImageHeader';
import '@assets/css/style.css';
import Info from '@components/partials/Info';
import Layout from '@hoc/layouts/Layout';
import Form from '@components/Play/Form';

const PlayPage = () => {
  const formRef = useRef(null);

  useEffect(() => {
    const scrollToForm = () => {
      if (formRef.current) {
        formRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    };

    requestAnimationFrame(() => {
      requestAnimationFrame(scrollToForm);
    });
  }, []);

  return (
    <Layout>
      <div className='relative'>
        <ImageHeader/>
      </div>
      <div>
        <Form ref={formRef}/>
      </div>
      <Info/>
    </Layout>
  );
};

export default PlayPage;
