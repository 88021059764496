import {notify} from 'react-notify-toast';
import moment from "moment";

export const showSuccess = (message = 'Success', timeout = 4000) => {
  notify.show(message, 'custom', timeout, {
    background: '#10b981',
    text: 'white',
  });
};
export const showCopiedFlash = (message = 'Success', timeout = 800) => {
  notify.show(message, 'custom', timeout, {
    background: '#EC7F00',
    text: 'white',
  });
};

export const showError = (error, timeout = 3000) => {
  notify.show(error, 'custom', timeout, {
    background: '#ef4444',
    text: 'white',
  });
};

export const showInfo = (message, timeout = 2000) => {
  notify.show(message, 'custom', timeout, {
    background: '#3b82f6',
    text: 'white',
  });
};

export const numbersOnly = (value) => {
  return value.replace(/[^0-9.]/g, '');
};

export const fileToBase64 = (file) => {
  if (!file) {
    return '';
  }
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      const splitArray = reader.result.split('base64,');
      resolve(splitArray[1]);
    };
    reader.onerror = (error) => reject(error);
  });
};
