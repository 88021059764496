import API from "@utils/plugins/API";

const DaysService = {
  addCode: ({payload}) => {
    return API.post('/users/add-code', payload)
  },
  getWinners: () => {
    return API.get('/users/winners-of-the-week')
  }
}

export default DaysService
