import React, { useState, useRef } from 'react';
import warning from '@assets/svg/warning.svg';

const CodeInput = ({ value, onChange, fields = 6, inputWidth = '35px', height = '45px', error }) => {
  const [code, setCode] = useState(value || Array(fields).fill(''));
  const inputRefs = useRef([]);

  const handleChange = (index, e) => {
    const newValue = e.target.value;
    const updatedCode = [...code];
    updatedCode[index] = newValue.slice(0, 1);

    setCode(updatedCode);
    onChange(updatedCode.join(''));

    if (newValue && index < fields - 1) {
      inputRefs.current[index + 1].focus();
    }
  };

  const handleKeyDown = (index, e) => {
    if (e.key === 'Backspace' && index > 0 && !code[index]) {
      inputRefs.current[index - 1].focus();
    }
  };

  const getDynamicStyles = () => {
    const isLargeScreen = window.innerWidth >= 1024;
    const width = isLargeScreen ? `${parseInt(inputWidth) + 7}px` : inputWidth;
    const inputHeight = isLargeScreen ? `${parseInt(height) + 7}px` : height;
    return {
      inputStyle: {
        fontFamily: 'inter',
        margin: '5px',
        MozAppearance: 'textfield',
        width,
        borderRadius: '5px',
        border: '1px solid #C12026',
        fontSize: '20px',
        height: inputHeight,
        boxShadow: 'rgb(222 222 222) 1px 1px 1px 1px',
        textAlign: 'center',
        outline: 'none',
        transition: 'border-color 0.2s ease-in-out',
        color: '#000',
        backgroundColor: '#fff',
      },
      containerStyle: {
        display: 'flex',
      },
    };
  };

  const { inputStyle, containerStyle } = getDynamicStyles();

  return (
    <div className='flex flex-col'>
      <div style={containerStyle}>
        {Array.from({ length: fields }, (_, index) => (
          <input
            key={index}
            ref={el => (inputRefs.current[index] = el)}
            type="text"
            value={code[index]}
            onChange={e => handleChange(index, e)}
            onKeyDown={e => handleKeyDown(index, e)}
            maxLength="1"
            style={inputStyle}
          />
        ))}
      </div>
      {error && (
        <label className="flex flex-row items-center xs:text-xs md:text-sm text-red-500 font-inter opacity-90 text-left p-1">
          <img src={warning} alt="warning" className='h-3 w-3 mr-1'/>
          {error}
        </label>
      )}
    </div>
  );
};

export default CodeInput;
