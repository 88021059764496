import React from 'react';
import warning from '@assets/svg/info.svg';

const WarningDropdown = ({ children, width = 'w-60', left = '-left-20 md:left-5' }) => {
  const [isOpen, setIsOpen] = React.useState(false);

  return (
    <div
      className="relative inline-flex"
      onMouseEnter={() => setIsOpen(true)}
      onMouseLeave={() => setIsOpen(false)}
    >
      <img
        src={warning}
        alt='warning'
        className='ml-2 hover:cursor-pointer hover:scale-150 transition duration-300 ease-in-out -mt-1 h-4 w-4'
      />
      {isOpen && (
        <div className={`shadow-lg absolute top-7.5 ${left} transform -translate-x-1/2 mt-2 ${width} rounded-lg bg-[#FFF9E6] p-4 text-[#C12026] z-50`}>
          {children}
          <div className="hidden md:absolute left-1/2 transform -translate-x-1/2 top-[-8px] w-0 h-0 border-x-8 border-x-transparent border-b-8 border-b-[#FFF9E6]"></div>
        </div>
      )}
    </div>
  );
};

export default WarningDropdown;
