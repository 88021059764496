import React, { useState, useRef, useEffect } from 'react';
import { format, parse, isValid } from 'date-fns';
import { DayPicker } from 'react-day-picker';
import calendar from '@assets/svg/calendar.svg';
import '@assets/css/style.css';

const DateInput = ({ label, error, containerClass, bgColor, required = true, value, setValue }) => {
  const [calendarOpen, setCalendarOpen] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);
  const calendarRef = useRef(null);

  const formatInput = (value) => {
    const numbersAndSlashes = value.replace(/[^0-9]/g, '');
    const chars = numbersAndSlashes.split('');

    if (chars.length > 2) chars.splice(2, 0, '/');
    if (chars.length > 5) chars.splice(5, 0, '/');

    return chars.join('');
  };

  const handleInputChange = (e) => {
    const formattedValue = formatInput(e.target.value);
    setValue(formattedValue);

    // Update selectedDate based on input value
    const parsed = parse(formattedValue, 'dd/MM/yyyy', new Date());
    if (isValid(parsed)) {
      setSelectedDate(parsed);
    }
  };

  const handleDateChange = (date) => {
    if (date) {
      const formattedDate = format(date, 'dd/MM/yyyy');
      setValue(formattedDate);
      setSelectedDate(date); // Update selectedDate
    }
    setCalendarOpen(false);
  };

  const handleClickOutside = (event) => {
    if (calendarRef.current && !calendarRef.current.contains(event.target)) {
      setCalendarOpen(false);
    }
  };

  useEffect(() => {
    if (calendarOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [calendarOpen]);

  const parsedValue = value ? parse(value, 'dd/MM/yyyy', new Date()) : null;
  const isDateValid = isValid(parsedValue);

  return (
    <div className={`w-full flex flex-col justify-start ${containerClass}`}>
      {label && (
        <label className="date-input-label">
          {label}
          {required ? <span>*</span> : <span>(optional)</span>}
        </label>
      )}
      <div className="relative">
        <input
          type="text"
          className={`placeholder:text-gray-500 placeholder:font-light text-black font-light font-inter border border-[#C12026] block w-full h-12 px-3 py-2 rounded-lg shadow-sm focus:outline-none focus:border-[#C12026] focus:ring-[#C12026] focus:ring-1 sm:text-sm mt-2 ${bgColor}`}
          placeholder="DD/MM/VVVV"
          value={value}
          onChange={handleInputChange}
          maxLength={10}
          aria-label="Date input"
        />
        <button
          type="button"
          className="date-input-calendar-btn"
          onClick={() => setCalendarOpen(!calendarOpen)}
          aria-label="Toggle calendar"
        >
          <img src={calendar} alt="calendar" className="h-4 w-4 mt-2" />
        </button>
        {calendarOpen && (
          <div ref={calendarRef} className="absolute z-30 custom-datepicker mt-2" style={{ top: '100%', right: 0 }}>
            <DayPicker
              mode="single"
              selected={isDateValid ? parsedValue : null}
              onSelect={handleDateChange}
              captionLayout="dropdown"
              disabled={{ after: new Date() }}
              defaultMonth={selectedDate}
            />
          </div>
        )}
      </div>
      {error && <label className="xs:text-xs md:text-sm text-red-500 font-inter opacity-90 text-left p-1">{error}</label>}
    </div>
  );
};

export default DateInput;
