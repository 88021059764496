import React, {useEffect, useState} from 'react';
import '@assets/css/style.css';
import {Link} from "react-router-dom";
import prize from "@assets/svg/prize.svg"
import DaysService from "@services/7DaysService";
import {hideSpinner, showSpinner} from "@redux/spinner/Action";
import {useDispatch} from "react-redux";
import {showError} from "@utils/helpers";
import GiftAnimation from '@assets/animations/gift.json'
import Lottie from "@core/Lottie";

const Fitues = ({dontShowBorder, winner}) => {
  return (
    <div
      className={`font-sans flex flex-col py-5 items-start justify-start ${!dontShowBorder && 'border-b border-[#000000] border-opacity-40'} mt-4`}>
      <div
        className='text-left tracking-wide text-[12px] lg:text-[16px] text-gray-400 font-inter font-thin'>{winner.date}</div>
      <div className='flex flex-col lg:flex-row lg:justify-between w-full lg:items-center mt-1.5'>
        <div className='flex flex-col'>
          <div
            className='text-left tracking-wide text-[14px] lg:text-[18px] font-inter font-semibold text-[#5A5A5A]'>{winner.winner_name}</div>
          {winner?.winner_city ? <div
            className='-mt-1 text-left tracking-wide text-[14px] lg:text-[18px] font-inter font-thin text-[#5A5A5A]'>{winner.winner_city}</div> : null}
        </div>
        <div
          className='text-left tracking-wide text-[14px] lg:text-[18px] font-inter font-thin text-[#5A5A5A]'>{winner.winner_gift}</div>
      </div>
    </div>
  )
}

const Winners = () => {
  const dispatch = useDispatch();
  const [winners, setWinners] = useState([]);

  useEffect(() => {
    dispatch(showSpinner());
    DaysService.getWinners()
      .then((response) => {
        setWinners(response.data.data)
      })
      .catch((err) => {
        showError(err.response?.data.message);
      })
      .finally(() => {
        dispatch(hideSpinner());
      });
  }, [dispatch]);

  return (
    <div className="relative bg-[#FFF9E6] w-full lg:pt-14">
      <div
        className='w-full lg:w-[950px] flex flex-col justify-center align-middle items-center text-center mx-auto bg-[#C12026] rounded-2xl py-10 px-6 lg:px-20 lg:pt-10 lg:pb-20 text-[#FFF9E6]'>
        <p className='font-bold mt-4 text-[30px]'>Fituesit e Promocionit</p>
        <p className='font-light mt-4 text-[18px]'>Loja promocionale do të zhvillohet nga data <b>09.09.2024</b> deri
          më <b>20.10.2024</b>. Shorteu hidhet çdo të Hënë (në ditët festive, zhvillohet ditën pasardhëse).</p>
        <p className='font-light mt-4 text-[18px]'>Shfletoni <Link
          className='font-semibold border-b border-white pb-0.5 mb-0.5' to='/rregullore'>Rregulloren</Link> për më shumë
          informacion.</p>
        <div
          className='flex flex-col justify-center items-center lg:w-[750px] bg-white rounded-2xl p-6 lg:p-12 mt-5 lg:mt-10 space-y-5'>
          <img alt='prize' src={prize} className='w-[190px] h-[110px]'/>
          <p className='font-semibold mt-5 text-[25px] text-[#C12026]'>Të gjithë fituesit e promocionit!</p>
          <div
            className='flex flex-col w-full border-2 border-[#C12026] px-5 lg:px-12 rounded-xl max-h-[700px] overflow-auto scroll-container'>
            {winners?.length ? winners.map((winner, index) => <Fitues winner={winner}
                                                                      dontShowBorder={index + 1 === winners.length}/>) :
              <div className="w-full my-10 flex flex-col items-center justify-center text-center mx-auto">
                <Lottie itemKey="default" animationData={GiftAnimation}/>
                <div className="text-sm font-normal text-center text-gray-500 mb-5">
                  Ende presim shpalljen e fituesve. Qëndroni me ne për të zbuluar rezultatin!
                </div>
              </div>
            }
          </div>
        </div>
      </div>
    </div>
  );
};

export default Winners;
