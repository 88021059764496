import { RiCloseFill } from 'react-icons/ri';
import {useScrollLock} from "../../hooks/useScrollLock";
import {useEffect} from "react";
const BlankModal = ({
                      id,
                      isOpen,
                      onClose,
                      children,
                      title,
                      subtitle,
                      width = 'w-11/12 md:w-3/5 lg:w-2/5',
                      otherButtons = [],
                      zIndex = 'z-50',
                      description,
                      top,
                      img,
                      padding = 'py-12'
                    }) => {
  const { unlockScroll, lockScroll } = useScrollLock();

  useEffect(() => {
    isOpen ? lockScroll() : unlockScroll();
  }, [isOpen, lockScroll, unlockScroll]);

  return (
    <div
      id={id}
      className={`fixed ${zIndex} ${top} inset-0 overflow-y-scroll flex items-center justify-center ${isOpen ? '' : 'hidden'}`}
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true">
      <div className="absolute inset-0 bg-gray-400 opacity-30" aria-hidden="true" onClick={onClose}/>
      <div
        className={`mx-5 rounded-2xl text-left overflow-hidden shadow-even ${width} transition-all ease-in-out duration-400`}
        role="dialog"
        aria-modal="true">
        <div className={`relative px-4 ${padding} bg-white`}>
          <div className="absolute top-0 right-0 mt-3 mr-3">
            <button onClick={onClose} className="hover:bg-[#ffe6e6] hover:bg-opacity-20 p-1 rounded-full">
              <RiCloseFill size={20} className="text-[#C12026] cursor-pointer" />
            </button>
          </div>
          <div className="flex flex-col items-center text-center justify-center lg:px-20">
            {img && (
              <img className='pb-4 w-[256px] h-[256px]' src={img} alt={img}/>
            )}
            {title && (
              <div className="font-sans text-lg lg:text-xl leading-6 text-[#C12026] font-semibold mt-4">{title}</div>
            )}
            {description && (
              <div className="font-sans text-sm lg:text-base font-light text-gray-500 lg:w-3/5 mt-3">{description}</div>
            )}
          </div>
          <div className='px-10'>
            <div className="mt-2">{children}</div>
            {otherButtons?.length > 0 && (
              <div className="flex flex-col gap-3 pt-5">
                {otherButtons?.map((button, index) => (
                  <div key={index} className="flex-1 first:ml-0 ml-2">
                    {button}
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlankModal;
