import React from 'react';
import Header from "@hoc/partials/Header";
import ImageHeader from "@components/partials/ImageHeader";
import RedFooter from "@hoc/partials/RedFooter";
import Info from "@components/partials/Info";
import List from "@components/Terms/List";

const TermsPage = () => {
  return (
    <>
      <div className='fixed'/>
      <Header/>
      <div className='relative'>
        <ImageHeader/>
      </div>
      <List/>
      <Info/>
      <RedFooter/>
    </>
  );
};

export default TermsPage;
