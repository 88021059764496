import React, { useEffect } from 'react';
import AOS from "aos";
import 'aos/dist/aos.css';
import instagram from "@assets/svg/socials/instagram.svg";
import facebook from "@assets/svg/socials/facebook.svg";

const RedFooter = () => {
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);

  return (
    <div className="w-full bottom-0 bg-[#C12026] font-inter relative">
      <div className="3xl:max-w-7xl 3xl:mx-auto py-3 flex flex-col lg:flex-row items-center justify-center lg:space-x-4">
        <div className='text-center font-semibold text-[#FFF9E6] font-inter leading-6 text-xs lg:text-sm'>
          Na ndiq për më shumë.
        </div>
        <div className="flex items-center mt-3 lg:mt-0 space-x-3">
          <img
            alt="facebook"
            src={facebook}
            className="lg:ml-3 transform duration-300 ease-in-out hover:scale-[0.90] h-6 w-6 hover:cursor-pointer"
            onClick={() => window.open('https://www.facebook.com', '_blank')}
          />
          <img
            alt="instagram"
            src={instagram}
            className="transform duration-300 ease-in-out hover:scale-[0.90] h-6 w-6 hover:cursor-pointer"
            onClick={() => window.open('https://www.instagram.com/7days_albania?igsh=YzB3aTN2cG1rN3Jo ', '_blank')}
          />
        </div>
      </div>
    </div>
  );
};

export default RedFooter;
