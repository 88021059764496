import React from 'react';

const Input = ({ label, error, containerClass, bgColor, required = true, type = 'text', ...props }) => {
  const showPrefix = type === 'phone';

  return (
    <div className={`w-full flex flex-col justify-start ${containerClass}`}>
      {label && (
        <label className={`font-semibold flex flex-row text-left xs:text-sm md:text-lg p-0.5 text-[#C12026]`}>
          {label}
          {required ? <span>*</span> : <span className='ml-1 font-light leading-6'>(opsionale)</span>}
        </label>
      )}
      <div className="relative">
        {showPrefix && (
          <span className="text-gray-500 font-light font-inter absolute inset-y-0 left-2.5 flex items-center pt-2.5 text-sm">+355</span>
        )}
        <input
          type={type}
          className={`placeholder:text-gray-500 placeholder:font-light text-black font-light font-inter border border-[#C12026] block w-full h-12 px-3 py-2 rounded-lg shadow-sm focus:outline-none focus:border-[#C12026] focus:ring-[#C12026] focus:ring-1 sm:text-sm mt-2 ${bgColor} ${showPrefix ? 'pl-12' : ''}`}
          {...props}
        />
      </div>
      {error && <label className="xs:text-xs md:text-sm text-red-500 font-inter opacity-90 text-left p-1">{error}</label>}
    </div>
  );
};

export default Input;
