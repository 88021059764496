import React from 'react';
import '@assets/css/style.css';
import banner from '@assets/svg/banner.png';
import bannerMobile from '@assets/svg/mobile_banner.png';

const ImageHeader = () => {
  return (
    <div className="flex w-full h-full items-center justify-center bg-[#FFF9E6]">
      <img
        alt="7Days"
        src={banner}
        className="hidden md:block max-w-full max-h-full sm:max-w-[1000px] sm:max-h-[auto]"
      />
      <img
        alt="7Days Mobile"
        src={bannerMobile}
        className="block -mt-10 md:hidden w-full h-auto"
      />
    </div>
  );
};

export default ImageHeader;
