import React from 'react';
import {Switch, BrowserRouter as Router, Redirect, Route} from 'react-router-dom';
import PlayPage from "@pages/PlayPage";
import WinnersPage from "@pages/WinnersPage";
import TermsPage from "@pages/TermsPage";
import ScrollToTop from "@router/ScrollToTop";

const Routes = () => {
  return (
    <Router>
      <ScrollToTop/>
      <Switch>
        <Route exact path="/" component={PlayPage}/>
        <Route exact path="/fituesit" component={WinnersPage}/>
        <Route exact path="/rregullore" component={TermsPage}/>
        <Redirect from="*" to="/"/>
      </Switch>
    </Router>
  );
};

export default Routes;
