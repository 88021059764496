import React from 'react';
import Header from "@hoc/partials/Header";
import RedFooter from "@hoc/partials/RedFooter";

const Layout = ({children}) => {
  return (
    <div className="container">
      <div className="h-full w-full absolute shadow-inner">
        <Header/>
        {children}
        <RedFooter/>
      </div>
    </div>
  );
};

export default Layout;
