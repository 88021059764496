import React from "react";
import Header from "@hoc/partials/Header";
import ImageHeader from "@components/partials/ImageHeader";
import "@assets/css/style.css"
import RedFooter from "@hoc/partials/RedFooter";
import Winners from "@components/Winner/Winners";
import Info from "@components/partials/Info";

const WinnersPage = () => {

  return (
    <>
      <div className='fixed'/>
      <Header/>
      <div className='relative'>
        <ImageHeader/>
      </div>
      <Winners/>
      <Info/>
      <RedFooter/>
    </>
  );
}

export default WinnersPage;
