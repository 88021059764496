const FormValidator = {
  name: {
    presence: {
      allowEmpty: false,
      message: 'Ju lutem vendosni emrin dhe mbiemrin tuaj'
    },
  },
  date: {
    presence: {
      allowEmpty: false,
      message: 'Ju lutem vendosni ditëlindjen tuaj'
    }
  },
  city: {
    presence: {
      allowEmpty: false,
      message: 'Ju lutem vendosni qytetin ku është kryer blerja'
    }
  },
  phoneNumber: {
    presence: {
      allowEmpty: false,
      message: 'Ju lutem vendosni numrin tuaj të telefonit'
    }
  },
  code: {
    presence: {
      allowEmpty: false,
      message: 'Ju lutem vendosni 6 karakteret e fundit të kodit NIVF'
    },
    length: {
      is: 6,
      message: 'Kodi duhet të ketë 6 karaktere'
    }
  },
  imageBase64: {
    presence: {
      allowEmpty: false,
      message: 'Ju lutem vendosni imazhin e faturës suaj'
    }
  },
  isOver18: {
    inclusion: {
      within: [true],
      message: 'Duhet të jeni mbi 18 vjeç'
    }
  },
  readTerms: {
    inclusion: {
      within: [true],
      message: 'Duhet të pranoni termat dhe kushtet'
    }
  }
};

export default FormValidator;
