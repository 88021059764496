import React, { useCallback, useEffect, useState } from 'react';
import logo from "@assets/svg/logo.svg";
import { Transition } from '@headlessui/react';
import { Link, useLocation } from 'react-router-dom';

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation();

  const [classes, setClasses] = useState('');
  const textClasses = "relative before:content-[''] before:absolute before:w-full before:h-[2px] before:bottom-0 before:left-0 before:hover:scale-x-100 before:scale-x-0 before:origin-top-left before:transition before:ease-in-out before:duration-300";
  const [extraClasses, setExtraClasses] = useState('');

  const changeBackground = useCallback(() => {
    if (window.innerWidth >= 1024) {
      const newClasses = window.scrollY >= 60 ? 'backdrop-blur bg-[#FFF9E6] bg-opacity-80' : '';
      const newTextClasses = window.scrollY >= 60 ? 'text-[#C12026] before:bg-[#C12026] border-[#C12026]' : 'text-[#C12026] before:bg-[#C12026] border-[#C12026]';
      setClasses(newClasses);
      setExtraClasses(newTextClasses);
    }
  }, []);

  useEffect(() => {
    changeBackground();
    window.addEventListener("scroll", changeBackground);

    return () => window.removeEventListener("scroll", changeBackground);
  }, [changeBackground]);

  const navLinks = [
    { to: '/', label: 'Luaj' },
    { to: '/fituesit', label: 'Fituesit' },
    { to: '/rregullore', label: 'Rregullore' },
  ];

  return (
    <nav className={`${classes} transition duration-300 fixed w-full justify-around z-40 md:py-2`}>
      <div className="mx-3 px-2 sm:px-6 lg:px-8 hidden lg:block">
        <div className="relative flex items-center justify-between h-14">
          <Link to='/'>
            <img className="h-10" src={logo} alt="Logo" />
          </Link>
          <div className="flex">
            {navLinks.map(link => (
              <Link
                key={link.to}
                className={`${location.pathname === link.to && 'border-b'} pb-0.5 mb-0.5 font-semibold font-inter text-base leading-5 mr-10 ${textClasses} ${extraClasses}`}
                to={link.to}
              >
                {link.label}
              </Link>
            ))}
          </div>
          <div/>
        </div>
      </div>

      <div className={`lg:hidden ${isOpen ? "main-nav-open" : ""}`}>
        <div className="flex flex-row justify-between p-4 items-center">
          <Link to="/">
            <img className="h-7" src={logo} alt="Logo" />
          </Link>
          <button
            id="mobile-menu"
            className="focus:outline-none mt-4 ml-auto mr-2"
            onClick={() => setIsOpen(!isOpen)}
            aria-controls="mobile-menu"
            aria-expanded="false"
          >
            <div className={`${isOpen ? 'open-burger' : 'burger'}`} />
          </button>
        </div>
        <Transition
          show={isOpen}
          enter="transition-opacity duration-75"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity duration-150"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="text-left" id="mobile-menu">
            <div className="flex flex-col px-10 py-4 space-y-2">
              {navLinks.map(link => (
                <React.Fragment key={link.to}>
                  <Link
                    className={`${location.pathname === link.to ? 'font-semibold' : 'font-normal'} pt-7 font-inter text-2xl leading-5 text-[#C12026] main-nav-text-open`}
                    to={link.to}
                  >
                    {link.label}
                  </Link>
                  <hr className={`${location.pathname === link.to ? 'font-semibold' : 'font-normal'} border-t-2 border-[#C12026]`} />
                </React.Fragment>
              ))}
            </div>
          </div>
        </Transition>
      </div>
    </nav>
  );
}

export default Header;
