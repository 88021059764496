import React from 'react';
import {Link} from "react-router-dom";
import {FaCircleNotch} from "react-icons/fa";

const DefaultButton = (
  {
    label,
    link,
    to,
    onClick,
    bgColor = 'bg-white',
    bgHoverColor = 'hover:scale-110',
    textColor = 'text-[#F40000]',
    xs = false,
    sm = false,
    loading = false,
    ...props
  }) => {
  let sizingClasses = 'py-4 px-11 text-lg'
  if (xs) {
    sizingClasses = 'py-1 px-3 text-xs'
  }
  if (sm) {
    sizingClasses = 'py-2 px-3 text-xs'
  }

  const className = `${bgColor} ${textColor} group ${bgHoverColor} w-full transition duration-300 ease-in-out  relative rounded-xl flex justify-center items-center border border-transparent font-semibold font-sans ${sizingClasses} focus:outline-none cursor-pointer disabled:opacity-60`

  if (link) {
    return  (
      <Link
        to={to}
        className={className}
        {...props}
      >
        {label}
      </Link>
    )
  }

  return (
    <button
      disabled={loading}
      onClick={loading ? null : onClick}
      className={className}
      {...props}
    >
      {loading && <FaCircleNotch size={20} className={'animate-spin-slow mr-2'} />}
      {label}
    </button>
  )
}

export default DefaultButton;

