import React from 'react';
import '@assets/css/style.css';
import clock from "@assets/svg/prizes/clock.svg";
import calendar from "@assets/svg/prizes/calendar.svg";
import coupon from "@assets/svg/prizes/coupon.svg";
const Info = () => {
  return (
    <div className="relative bg-[#FFF9E6] w-full cursor-auto lg:pt-20">
        <div className='grid grid-cols-1 lg:grid-cols-3 lg:w-[920px] gap-10 lg:gap-24 px-14 lg:px-0 py-14 items-center justify-center mx-auto'>
          <div className="flex flex-col items-center text-center justify-center">
            <img alt='clock' src={clock} className='h-16 w-16'/>
            <p className='text-[#C12026] font-bold text-[20px] mt-4'>Çmime ditore</p>
            <p className='text-[15px] font-inter font-light opacity-90 flex-grow'>
              <b className='font-semibold'>44 fitues</b> të Kuponit FGA <b className='font-semibold'>me vlerë 7,000 Lekë</b>.
            </p>
          </div>
          <div className="flex flex-col items-center text-center justify-center">
            <img alt='calendar' src={calendar} className='h-16 w-16'/>
            <p className='text-[#C12026] font-bold text-[20px] mt-4'>Çmime javore</p>
            <p className='text-[15px] font-inter font-light opacity-90 flex-grow'>
              <b className='font-semibold'>6 fitues</b> të Kuponit NEPTUN <b className='font-semibold'>me vlerë 35,000 Lekë</b>.
            </p>
          </div>
          <div className="flex flex-col items-center text-center justify-center">
            <img alt='coupon' src={coupon} className='h-18 w-18'/>
            <p className='text-[#C12026] font-bold text-[20px] mt-4'>Çmimi i madh</p>
            <p className='text-[15px] font-inter font-light opacity-90 flex-grow '>
             <b className='font-semibold'>1 fitues</b> i Kuponit “Udhëtim në çift në VIP Trip Festival”</p>
          </div>
        </div>
    </div>
  );
};

export default Info;
