import React from 'react';

const RadioButton = ({ checked, onChange}) => {

  return (
    <label className="flex items-center cursor-pointer" onClick={() => onChange(!checked)}>
      <div className={`w-[18px] h-[18px] border-2 rounded-full flex items-center justify-center border-[#C12026]`}>
        <div className={`w-2.5 h-2.5 rounded-full ${checked ? 'bg-[#C12026]' : 'bg-transparent'}`} />
      </div>
    </label>
  );
};

export default RadioButton;
